<template>
  <div class="target">
    <head-nav></head-nav>
    <about-img></about-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="target-title">
        <img
          :src="$t('about.title-7')"
          alt=""
        />
      </h3>
      <div class="target-imgs">
        <div class="target-img">
          <img
            class="target-img-left"
            src="@/assets/image/about/targetLeft.png"
            alt=""
          >
          <img
            class="target-img-right"
            src="@/assets/image/about/targetRight.png"
            alt=""
          >
          {{$t('about.about-target.list-content-1')}}
        </div>
      </div>

      <div class="target-box">
        <div>
          <img
            :src="$t('about.title-8')"
            alt=""
          />
        </div>

        <div class="target-boxs">
          <div
            class="list"
            v-for="(item, index) in  $t('about.about-target.list-1')"
            :key="index"
          >
            <div class="list-top">
              <span class="list-info">No.</span>
              <span class="list-nums">{{  (index + 1).toString().padStart(2, '0') }}</span>
            </div>

            <div class="list-bottom">
              <div class="list-img">
                <img
                  class="list-imgs"
                  :src="item.img"
                  alt=""
                />
              </div>

              <div class="list-content">
                <p>{{ item.title }}</p>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>

import returnImg from '@/assets/image/product/fanhui.png';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    AboutNav,
  },
  data() {
    return {
      breadcrumb: [
        {
          label: 'about.target',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.target {
  .wrap {
    padding: 88px 0 150px 0;

    .target-title {
      text-align: center;
    }

    .target-imgs {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .target-img {
        background: url(../assets/image/about/target1.png) no-repeat;
        background-size: 100% 100%;
        padding: 0 30px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 34px;
        position: relative;

        &-left {
          position: absolute;
          top: 20px;
          left: -70px;
        }
        &-right {
          position: absolute;
          right: -70px;
          top: 20px;
        }
      }
    }

    .target-box {
      text-align: center;
      width: 100%;
      margin-top: 60px;
      padding-top: 90px;
      border-top: 1px solid #eeeeee;

      .target-boxs {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 40px;

        .list {
          width: 200px;
          height: 280px;
          background: #eeeeee;

          .list-top {
            width: 100%;
            height: 80px;
            background: #ffbf00;
            position: relative;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-width: 12px 14px 0;
              border-style: solid;
              border-color: #ffbf00 transparent transparent;
              position: absolute;
              bottom: -10px !important;
              right: 30px;
              z-index: 99;
            }

            .list-info {
              font-size: 14px;
              color: #fff;
              position: absolute;
              top: 12px;
              left: 20px;
            }

            .list-nums {
              font-size: 48px;
              color: #000;
              font-weight: 900;
              position: absolute;
              top: 25px;
              left: 20px;
            }
          }

          .list-bottom {
            margin-top: 40px;

            .list-img {
              .list-imgs {
                width: 44px;
                height: 44px;
                margin-bottom: 10px;
              }
            }

            .list-content {
              p {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
